import env from '../environment';
import axios from 'axios';

class AccountService {
    static API_URL = env.API_URL;
    static async sendOTP(phone){
        try {
            const response = await axios.post(`${AccountService.API_URL}/user/delete-send-otp`, { phone });
            console.log('send OTP: ', response.data);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message === 'Invalid phone number!') {
                throw new Error('Invalid phone number!');
            } else {
                throw new Error('Failed to send OTP');
            }
        }
    }

    static async resendOTP(phone){
        try {
            const response = await axios.post(`${AccountService.API_URL}/user/delete-send-otp`, { phone });
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message === 'Invalid phone number!') {
                throw new Error('Invalid phone number!');
            } else {
                throw new Error('Failed to send OTP');
            }
        }
    }

    static async verifyOTP(phone, otpCode){
        try {
            const response = await axios.post(`${AccountService.API_URL}/user/delete-verify-otp`, { phone, otpCode });
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message === 'Invalid OTP.') {
              throw new Error('Invalid OTP!');
            } else {
              throw new Error('Failed to verify OTP!');
            }
        }
    }
}

export default AccountService;
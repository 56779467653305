import React, { useState } from "react";
import skimLogo from '../assets/img/skimLogo.png';
import AccountService from "../services/account.service";

const timerDuration = 15; //60 seconds

function DeleteAccount() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [otp, setOtp] = useState('');
  const [activeState, setActiveState] = useState(1);
  const [disabledResent, setDisabledResent] = useState(true);
  const [timer, setTimer] = useState(0);

  const handlePhoneChange = (e) => {
    const val = e.target.value;
    if(val.length === 0){
      setPhoneNumber('');
      return;
    }
    const numericRegex = /^[0-9]+$/;
    if(numericRegex.test(val) && val.length <= 10){
      setPhoneNumber(val);
    }
  }

  const handleOTPChange = (e) => {
    const val = e.target.value;
    if(val.length === 0){
      setOtp('');
      return;
    }
    const numericRegex = /^[0-9]+$/;
    if(numericRegex.test(val) && val.length <= 4){
      setOtp(val);
    }
  }
  const startResendTimer = () => {
    setTimer(0);
    const id = setInterval(() => {
      setTimer(x => x+1)
    }, 1000)
    setTimeout(() => {
      clearInterval(id);
      setTimer(0);
      setDisabledResent(false);
    }, timerDuration*1000);
  }
  const displayErrorMessage = (message) => {
    setErrorMessage(message);
      setTimeout(() => {
        setErrorMessage('');
    }, 3000);
  }
  const sendOTP = async () => {
    if(phoneNumber.length === 10){
      try {
        await AccountService.sendOTP(phoneNumber);
        setDisabledResent(true);
        setActiveState(2);
        startResendTimer();
      } catch (error) {
        displayErrorMessage(error.message);
      }
    }else{
      displayErrorMessage('Invalid phone number!');
    }
  }

  const verifyOtp = async () => {
    if(otp.length < 4){
      displayErrorMessage('Invalid OTP!');
    }else{
      try {
        await AccountService.verifyOTP(phoneNumber, otp);
        setActiveState(3);
      } catch (error) {
        displayErrorMessage(error.message);
      }
    }
  }

  const resendOTP = async () => {
    if(disabledResent) return;
    try {
      await AccountService.resendOTP(phoneNumber);
      setOtp('');
      setDisabledResent(true);
      startResendTimer();
    } catch (error) {
      displayErrorMessage(error.message);
    }
  }
  return (
    <div className="flex justify-center items-center min-h-screen py-6 bg-dark">
      <div className="max-w-sm md:max-w-xs md:max-w-md w-full mx-6 bg-white rounded-lg shadow-md p-6 sm:p-10 relative">
        <div
          className="absolute w-4/5 top-0 left-[50%] -translate-y-[25%] -translate-x-[50%] shadow-md rounded-md text-sm mb-4 py-3 px-12 text-center bg-blue text-white"
          data-title="Delete User Account"
        >
          Delete Account
        </div>
        <div className="text-center mb-4 mt-6">
          <img
            src={skimLogo}
            alt="Company Logo"
            className="mx-auto w-20 md:w-20"
          />
        </div>

        <div className="space-y-4">
          {activeState === 1 && 
            <div>
              <label htmlFor="phoneNumber" className="block text-sm mb-1">
                Phone Number
              </label>
              <div className="relative mb-4">
                <span className="absolute top-[50%] -translate-y-[50%] left-2 leading-[1.5rem] text-gray-500 font-semibold">+91</span>
                <input
                  type="text"
                  placeholder="9876543210"
                  className="w-full border rounded-md py-2 pl-10 focus:outline-none font-500"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                />
              </div>
              { errorMessage.length > 0 && 
                <div className="error-message text-sm text-red-500 mb-4 -mt-3">
                  {errorMessage}
                </div>
              }
              <div>
                <button
                  type="button"
                  className="bg-blue text-white px-4 py-2 rounded-md w-full hover:bg-blue-600 focus:outline-none"
                  onClick={sendOTP}
                >
                  Send OTP
                </button>
              </div>
            </div>
          }
          {activeState === 2 && 
            <div>
              <div className="text-sm text-center my-4" id="otpMessage">
                One Time Password (OTP) has been sent to your mobile number <span className="block font-semibold">{`+91 XXXXXX${phoneNumber.slice(6)}`}</span>
              </div>
              <label htmlFor="otp" className="block text-sm mb-1">
                Enter OTP
              </label>
              <input
                type="text"
                className="w-full border rounded-md py-2 px-3 focus:outline-none"
                value={otp}
                onChange={handleOTPChange}
              />
              { errorMessage.length > 0 && 
                <div className="error-message text-sm text-red-500 mb-2">
                  {errorMessage}
                </div>
              }
              <p className="text-sm text-center mt-2">
                {disabledResent && <span className="block text-xs">You can resend OTP in {timerDuration - timer} seconds</span>}
                <button
                  type="button"
                  disabled={disabledResent}
                  className="text-blue font-semibold hover:text-blue focus:outline-none disabled:text-gray-600"
                  onClick={resendOTP}
                >
                  Resend OTP
                </button>
              </p>
              <button
                type="submit"
                className="bg-blue text-white px-4 py-2 rounded-md w-full mt-4 hover:bg-blue-600 focus:outline-none"
                onClick={verifyOtp}
              >
                Submit
              </button>
            </div>
          }
        </div>
        {
          activeState === 3 && 
          <div
            className="text-center font-semibold mb-4 text-success"
          >
            <svg
              className="inline-block w-6 h-6 fill-current mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M0 11l2-2 5 5L18 3l2 2L7 20z" />
            </svg>
            <span className="text-center">Your Account has been deleted successfully.</span>
          </div>
        }
      </div>
    </div>
  );
}

export default DeleteAccount;
